.react-daterange-picker {
    font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.react-daterange-picker__wrapper {
    font-size: 16px;
    max-height: 50px;
    border-radius: 4px;
    border-width: 1px;
    padding: 6px;
    border-color: #000000;
    background-color: #ffffff;
}

.react-daterange-picker__button {
    padding: 1px;
}

.react-daterange-picker__inputGroup {
    min-width: auto;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar {
    border-radius: 6px;
    overflow: hidden;
    padding: 4px;
}

.react-calendar__navigation {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.react-calendar__navigation__label {
    text-transform: capitalize;
}

.react-calendar__navigation button:disabled {
    background-color: unset;
    color: unset;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__viewContainer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

.react-calendar__tile--now {
    background-color: #096c86;
    color: #ffffff;
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive,
.react-calendar--selectRange .react-calendar__tile--hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--hasActive:enabled:focus {
    background-color: #dbf6fd;
    color: #000000;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--hasActive:enabled:hover {
    background-color: #e6e6e6;
}
